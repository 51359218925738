import React from "react";
import GoogleMapReact from "google-map-react";
// 23.5365468,87.2938764,17
import { FaMapMarkerAlt } from "react-icons/fa";
const defaultProps = {
  center: {
    lat: 22.5687,
    lng: 88.4316,
  },
  zoom: 24,
};

const renderMarkers = (map: any, maps: any) => {
  let marker = new maps.Marker({
    position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
    map,
    title: "dsss",
  });
  return marker;
};

const Marker = (props: any) => {
  return (
    <div className="SuperAwesomePin" {...props}>
      <FaMapMarkerAlt className="text-red-500" size={36} />
    </div>
  );
};

const MapComponent = () => {
  return (
    <div className=" w-full md:h-full h-[250px] ">
      <GoogleMapReact
        defaultZoom={12}
        defaultCenter={defaultProps.center as any}
        bootstrapURLKeys={{ key: `AIzaSyBcWIlRmRnujZSfiMXmE4Ox9ZTvvwGU8bE` }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
        {/* {places.map((place) => (
              <Marker
                key={place.id}
                lat={place.geometry.location.lat}
                lng={place.geometry.location.lng}
                show={place.show}
                place={place}
              />
            ))} */}
        {/* <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} /> */}
      </GoogleMapReact>
    </div>
  );
};

export default MapComponent;
