import React, { FC, ReactNode, useRef } from "react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import Footer from "./Footer";
import CookieBottomModal from "./CookieModal";

interface LayoutProps {
  children: ReactNode;
  ScrollToTopPos: any;
  scrollIntoTop: any;
  contactUsRef: any;
}
const Layout: FC<LayoutProps> = ({
  children,
  ScrollToTopPos,
  scrollIntoTop,
  contactUsRef,
}) => {
  const gotoBottomAction = () => {
    if (contactUsRef?.current) {
      console.log("footer hit");
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="w-full h-auto   flex flex-col bg-primaryBgColor text-white relative    ">
      <TopNav ScrollToTopPos={ScrollToTopPos} />
      <CookieBottomModal />
      {children}
      <Footer ScrollToTopPos={ScrollToTopPos} contactUsRef={contactUsRef} />
    </div>
  );
};

export default Layout;
