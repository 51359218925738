import React, { FC } from "react";
import OurCulture from "./components/OurCulture";
import NewsSection from "./components/NewsSection";
import { MainTitle } from "./components/MainTitle";
import { ProductBanner } from "./components/ProductBanner";
import { MainTitleTypeTwo } from "./components/MainTitleTypeTwo";
import HomeCarousal from "./components/HomeImageCarousal";
import HomeCarousalWrapper from "./components/HomeCarousalWrapper";

import AwardSection2 from "./components/AwardSection2";
import ProductSection from "./components/ProductSection";
import ContactUs from "./components/ContactUs";
import GenAISection from "./components/GenAISection";
import AboutUS from "./components/AboutUs";
import EnterpriseApp from "./components/EnterpriseApp";
import SAPSection from "./components/SAPSection";

interface LandingPageProps {
  contactUsRef: any;
}
const LandingPage: FC<LandingPageProps> = ({ contactUsRef }): JSX.Element => {
  return (
    <div className="flex flex-col w-full h-full lg:mt-[90px] ">
      <MainTitle />

      <ProductSection />
      <AboutUS />
      <div id="services_msqube" className="mt-[40px]">
        <MainTitleTypeTwo
          textBlocks={["SERVICE", "OFFERINGS"]}
          className="py-10"
          innerClassName="lg:min-w-[292px]"
        />
      </div>
      <HomeCarousalWrapper />
      {/* <AwardSection /> */}
      <AwardSection2 />

      {/* <OurCulture /> */}
      <NewsSection />
      <EnterpriseApp />
      <SAPSection />
      <GenAISection />

      {/* <ContactUs /> */}
    </div>
  );
};

export default LandingPage;
