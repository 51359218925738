import bg1 from "./assets/bg1.jpg";
import bg2 from "./assets/bg2.jpg";
import digital_transformation from "./assets/digital_transformation.png";
import architecture_consulting from "./assets/EnterpriseArchitecture.jpg";
import enterprize_cloud from "./assets/enterprize_cloud.jpg";
import appDevelopment from "./assets/appDevelop.png";
import product1 from "./assets/product1.png";
import product3 from "./assets/product3.png";
import smartBuisness from "./assets/smart_buisness.jpg";
import product7 from "./assets/proudct7.png";
import product4 from "./assets/product4.png";
import hrmsImage from "./assets/hrms1.jpg";
import hrmsImage2 from "./assets/hrms2.png";
import qubefms from "./assets/qubefms._new.jpg";
import qubeMatics from "./assets/qubeMatics2.jpg";
import qubeMatics3 from "./assets/qubematics3.png";
import qubeCart2 from "./assets/qube_cart_new.png";
import aquaQube2 from "./assets/aquaqube_new.png";
import ekycImage from "./assets/ekyc_new.jpg";

import qii1 from "./assets/qii1.png";
import igms11 from "./assets/hrms_2new.png";

export const environments = {
  bannerImageList: [
    {
      image: bg1,
      title: "",
    },
    {
      image: bg2,
      title: "",
    },
  ],
  serviceSection: [
    {
      bannnerImage: digital_transformation,
      title: "Digital Transformation",
      description: `Digital Transformation services
    focuses on leveraging digital
    technologies (IoT, Machine
    Learning / AI, Blockchain) leading
    to enhanced customer experience.`,
    },
    {
      bannnerImage: enterprize_cloud,
      title: "Enterprise Cloud Services",
      description: `Enterprise Cloud Services is aimed
      towards partnering with you in your
      end to end Cloud migration journeythic
      of IT applications and infrastructure.`,
    },

    {
      bannnerImage: architecture_consulting,
      title: "Enterprise Architecture Consulting",
      description: `Enterprise Architecture Consulting
      is geared towards application
      rationalization and modernization
      for your IT portfolio.`,
    },
    {
      bannnerImage: appDevelopment,
      title: "Application Development",
      description: `Application Development caters
      to modern day organizational
      need of Microservices
      development, API certification
      and DevOps transformation`,
    },
  ],
  productsList: [
    {
      title: "QUBEMATICS",
      subtitle:
        "Maintain Quality and Hygine, Monitor Anytime Anywhere on the Go",
      description: `QubeMatics provides an efficient, cost effective solution by
      leveraging advanced IOT and Cloud platform that enables you
      driving better business results, run your business differently and
      sustainably.`,
      navigateLink: "https://gobykes.com/",
      bgImage: qubeMatics3,
      hoverImage: `linear-gradient(to top,#20A8D2,#63C6E4,#20A8D2)`,
    },
    {
      title: "QUBECART",
      subtitle:
        "Mobile Based Ecommerce Application comes with various capability",
      description: `Online ordering platform for Food, Grocery and garments from your trusted local stores\n Parents can place ers for book/stationary/garment pacakges for their 
      kids for the concerned academic year`,
      navigateLink: "https://gobykes.com/",
      bgImage: qubeCart2,
      hoverImage: `linear-gradient(to top,#ffffff,#f1f2f1,#f1f1f1)`,
      textColor: "black",
    },
    {
      title: "eKYC & vKYC",
      subtitle: "Digital eKYC and vKYC platform as per RBI guidelines",
      description: ` Facing reconition using deep learning Convolutional Neural Network (CNN), Aadhar, Voter,PAN card validation,
      Blockchain based consortium for digital KYC sharing`,
      navigateLink: "www.ekycverify.com",
      bgImage: ekycImage,
      hoverImage: `linear-gradient(to top,#20A8D2,#63C6E4,#20A8D2)`,
    },
    {
      title: "IGMS",
      subtitle:
        "Integrated Grievance Management System with skill based routing and auto assignment",
      description: `Grievance management platform,skill based routing, SLA definition, Alert & notification, Automated assignment & reassignment, Integrated web and mobile apptanay`,
      navigateLink: "https://prdgrievance.wb.gov.in/",
      bgImage: igms11,
      bottom: true,
      hoverImage: `linear-gradient(to top,#ffffff,#f1f2f1,#f1f1f1)`,
      textColor: "black",
    },
    {
      title: "QUBEFMS",
      subtitle:
        "Optical Fibre Management system with Network Diagram, Service Catalogue and Fault Tolerance",
      description: `Tracing cable path, service network, Zone and station wise cable distribution including road side underground and overhead joints, managing different FMS - FDB, FODB,TJB, GCO, managing fibre splicing, 
      patching and termination, managing faults.`,
      navigateLink: "https://prdgrievance.wb.gov.in/",
      bgImage: qubefms,
      hoverImage: `linear-gradient(to top,#20A8D2,#63C6E4,#20A8D2)`,
    },
    {
      title: "AQUAQUBE",
      subtitle: "AQUAQUBE allows fish farmers to monitor water quality",
      description: `Predict the growth of their fish and adjust their feeding regimes approproiately. Reduce operation cost by 15% - 20%, mobile app with 24*7 water quality check, IOT based sensors that measure 
      parameters like pH, dissolved oxygen (DO) and temparature   `,
      navigateLink: "https://prdgrievance.wb.gov.in/",
      bgImage: aquaQube2,
      hoverImage: `linear-gradient(to top,#ffffff,#f1f2f1,#f1f1f1)`,
      textColor: "black",
    },
    {
      title: "QUBEINFRAINSIGHT",
      subtitle:
        "30% faster than traditional ERP's & easily customisable for any industry",
      description: `QII is specially designed as 360 degree single window application
      that automates entire value chain for any Company into
      Construction and infrastructure business. `,
      navigateLink: "https://prdgrievance.wb.gov.in/",
      bgImage: qii1,
      hoverImage: `linear-gradient(to top,#20A8D2,#63C6E4,#20A8D2)`,
    },

    {
      title: "HRMS",
      subtitle:
        "HRMS platform to manage Employee Attendance, Payout & Project Budgeting",
      description: `Employee onboarding, customer and project setup, salary structure and prcessing, Attendance management, Leave management, project budgeting and forcasting, analytics & reporting`,
      navigateLink: "https://prdgrievance.wb.gov.in/",
      bgImage: hrmsImage2,
      hoverImage: `linear-gradient(to top,#ffffff,#f1f2f1,#f1f1f1)`,
      textColor: "black",
    },
  ],
  technologicalCapabilities: [
    {
      title: `Architecture Practice`,
      description: `Cloud Native, Microservices based application design, Event driven architecture for high throughput, Enterprise Integration Patterns, 12 factor principles`,
    },
    {
      title: `Cloud Practice`,
      description: `AWS, GCP, Azure Serverless Computing, Docker, Kubernetes, auto Scaling `,
    },

    {
      title: `Backend Technologies`,
      description: `Python, NodeJS, Java, Kotlin `,
    },
    {
      title: `Frontend Technologies`,
      description: `Angular, ReactJS, React Native, Flutter`,
    },
    {
      title: `Database Technologies`,
      description: `MySQL, Postgres, MongoDB`,
    },
    {
      title: `Middleware / Integration`,
      description: `Kafka, API Gateway, Apache Airflow`,
    },
    {
      title: `Product Coverage`,
      description: `SAP, Elastic Path Commerce, DMS, Salesforce, MS Dynamics, Power BI`,
    },
    {
      title: `Consulting Capabilities`,
      description: `Large scale digital transformation, Legacy modernization, Cut over strategy, Business case preparation, TCO calculation`,
    },
  ],
  industryExperience: [
    {
      title: `State owned farm to fork initiative`,
      details: `We manage entire IT Operations of a state owned Agri Marketing initiative covering 25k farmers and 500 stores across the state. The entire lifecycle of procurement, inventory management, pricing management, POC, billing and return are managed through our ERP & Billing platform. 
      Daily sales of Rs 10L+ is now managed through this platform with a facility of realtime reporting and analytics.
      `,
    },
    {
      title: `State owned transport & ticketing platform`,
      details: `We manage a state owned transport service with online ticket booking facility for citizen. Close to 1500 buses are tracked and managed through this platform with a daily ticket sales of approx. Rs 15L. Both online and counter tickets are managed through this platform.`,
    },
    {
      title: `Manage optical fibre network `,
      details: `We manage 10L KM of Optical Fibre Network for one of the largest Distribution Companies in India. Our platform helps them in finding faults, restore services, find out alternate routes during outages, efficiently plan new service routing etc. We generate network diagrams considering cable joins and splicing across multiple substations and generation plants.`,
    },
  ],
  genaiDetails: [
    { text: "Comparative study of LLM models - LLAMA, OpenAI, Gemini" },
    { text: "Implement Q&A using Langchain PromptTemplate" },
    { text: "Multimodal LLM offering" },
    { text: "GenAI based image reading and translation" },
    { text: "Speech to text with sentiment analysis " },
    { text: "RAG implementation on GenAI" },
    { text: "Comparison of VectorDB - FAISS, Milvus, Chroma" },
  ],
};
