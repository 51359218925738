import React, { FC } from "react";
import { IoCallSharp } from "react-icons/io5";
import { HiEnvelope } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../models/reduxModels";
import msqubeImage from "../assets/msqube_logo.png";
import { IoIosArrowDown } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import MenuForMobile from "./MenuForMobile";
import NavMenu from "./NavMenu";
import { mobileMenuOpenHandler } from "../stores/actions/globalAction";
interface TopNavProps {
  ScrollToTopPos: any;
}
const TopNav: FC<TopNavProps> = ({ ScrollToTopPos }): JSX.Element => {
  const navScrolled = useSelector(
    (state: StoreState) => state.globals.navScrolled
  );
  const dispatch = useDispatch();
  const serviceClick = () => {
    document
      .getElementById("productSection_msqube")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const whatWeDoHandler = () => {
    document
      .getElementById("services_msqube")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const contactUsMsqubeHandler = () => {
    console.log("contact us msqube");
    document
      .getElementById("contactUsMsqube")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const GenAIUseHandler = () => {
    document
      .getElementById("genAiSection")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const technologyHandler = () => {
    document
      .getElementById("technlogy_capability")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const aboutUsHandler = () => {
    document
      .getElementById("aboutUsMSQUBE")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  console.log("navScrolled: ", navScrolled);
  const openDrawer = () => {
    dispatch(mobileMenuOpenHandler() as any);
  };
  return (
    <div
      className={`w-screen fixed   bg-primaryBgColor flex flex-row items-center gap-x-5 flex justify-between lg:px-[80px] py-5  z-[100]   duration-500 lg:fixed  w-screen transition-all ${
        navScrolled ? `lg:top-[0%]  lg:left-0` : "lg:-top-[20%]"
      } `}
    >
      <div className="lg:w-[25%]  ">
        <img
          src={msqubeImage}
          alt="logo"
          className="lg:w-[50px]  w-[40px] ml-2"
        />
      </div>
      <div className="absolute right-[5%] lg:hidden">
        <RxHamburgerMenu
          size={22}
          onClick={openDrawer}
          className="cursor-pointer"
        />
      </div>
      <div className="block lg:hidden  mr-5">
        <MenuForMobile
          ScrollToTopPos={ScrollToTopPos}
          serviceClick={serviceClick}
          whatWeDoHandler={whatWeDoHandler}
          contactUsMsqubeHandler={contactUsMsqubeHandler}
          GenAIUseHandler={GenAIUseHandler}
          technologyHandler={technologyHandler}
          aboutUsHandler={aboutUsHandler}
        />
      </div>
      <div className="lg:w-[76%] lg:flex  justify-between  items-center hidden ">
        <div className="flex items-center gap-x-1 cursor-pointer">
          <p
            className="font-[400] hover:underline underline-offset-2 duration-200 ease-in-out"
            onClick={ScrollToTopPos}
          >
            Home
          </p>
        </div>
        <div className="flex items-center gap-x-1 cursor-pointer">
          <p
            className="font-[400] hover:underline underline-offset-2 duration-200 ease-in-out"
            onClick={serviceClick}
          >
            Our Products
          </p>
          {/* <IoIosArrowDown /> */}
        </div>
        <div className="flex items-center gap-x-1 cursor-pointer">
          <p
            className="font-[400] hover:underline underline-offset-2 duration-200 ease-in-out"
            onClick={aboutUsHandler}
          >
            About Us
          </p>
          {/* <IoIosArrowDown /> */}
        </div>
        <div className="flex items-center gap-x-1 cursor-pointer">
          <p
            className="font-[400] hover:underline underline-offset-2 duration-200 ease-in-out"
            onClick={whatWeDoHandler}
          >
            Our Services
          </p>
          {/* <IoIosArrowDown /> */}
        </div>

        <div className="flex items-center gap-x-1 cursor-pointer">
          <p
            className="font-[400] hover:underline underline-offset-2 duration-200 ease-in-out"
            onClick={technologyHandler}
          >
            Technology Capability
          </p>
          {/* <IoIosArrowDown /> */}
        </div>

        <div className="flex items-center gap-x-1 cursor-pointer">
          <p
            className="font-[400] hover:underline underline-offset-2 duration-200 ease-in-out"
            onClick={contactUsMsqubeHandler}
          >
            Contact Us
          </p>
          {/* <IoIosArrowDown /> */}
        </div>
      </div>
      <div className="lg:w-[12%] hidden lg:flex flex-col  flex gap-x-5 justify-between  items-center"></div>
      {/* <NavMenu /> */}
    </div>
  );
};

export default TopNav;
