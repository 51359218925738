import React from "react";
import { useDispatch, useSelector } from "react-redux";

import cookie from "../../assets/cookie.png";
import { StoreState } from "../models/reduxModels";
import { closeCookieModal } from "../stores/actions/globalAction";
// import {
//   setCookieModal,
//   closeCookieModal,
// } from "../../store/actions/globalAction";
const CookieBottomModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(
    (state: StoreState) => state.globals.isCookieEnabled
  );
  const alterCookieModalHandler = () => {
    dispatch(closeCookieModal() as any);
  };
  const acceptCookieModalHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem("cookieEnabled", "yes");
    dispatch(closeCookieModal() as any);
  };
  const denyCookieModalHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // localStorage.setItem("cookieEnabled", "yes");
    dispatch(closeCookieModal() as any);
  };
  if (!isModalOpen) return <></>;
  return (
    <div className="flex h-[300px]   md:h-[80px] bg-[#1B1D1E] py-6 px-6 items-center  w-screen   z-[999] fixed bottom-0 left-0">
      <div className="w-full  capitalize !text-[11px] flex justify-between text-white items-center flex-col lg:flex-row ">
        <div className="flex justify-start items-center space-x-6 flex-col lg:flex-row">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M11.125 11.1538H12.875V3.83301H11.125V11.1538ZM12 15.2955C12.2722 15.2955 12.5007 15.2031 12.6854 15.0184C12.8701 14.8337 12.9625 14.6052 12.9625 14.333C12.9625 14.0608 12.8701 13.8323 12.6854 13.6476C12.5007 13.4629 12.2722 13.3705 12 13.3705C11.7278 13.3705 11.4993 13.4629 11.3146 13.6476C11.1299 13.8323 11.0375 14.0608 11.0375 14.333C11.0375 14.6052 11.1299 14.8337 11.3146 15.0184C11.4993 15.2031 11.7278 15.2955 12 15.2955ZM0.333344 23.6663V2.08301C0.333344 1.63579 0.508344 1.23231 0.858344 0.872591C1.20834 0.512869 1.61668 0.333008 2.08334 0.333008H21.9167C22.3639 0.333008 22.7674 0.512869 23.1271 0.872591C23.4868 1.23231 23.6667 1.63579 23.6667 2.08301V17.2497C23.6667 17.6969 23.4868 18.1004 23.1271 18.4601C22.7674 18.8198 22.3639 18.9997 21.9167 18.9997H5.00001L0.333344 23.6663ZM2.08334 19.4372L4.27084 17.2497H21.9167V2.08301H2.08334V19.4372ZM2.08334 2.08301V19.4372V2.08301Z"
              fill="currentColor"
            ></path>
          </svg>
          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
            }}
            className="text-white !text-[14px] w-[75%]"
          >
            We place cookies on your device to enable this site to work, to
            enhance your user experience and to improve our services. Some
            cookies we use are necessary for the site to work, while others are
            used to help us manage and improve the site and the services we
            offer you.
          </p>
        </div>
        <div className="  flex justify-end gap-x-3 mt-5 lg:mt-0  ">
          <button
            onClick={acceptCookieModalHandler}
            className=" px-2 py-[2px] text-white rounded-md   duration-300  transition-all ease-in-out text-[19px]"
          >
            Accept
          </button>
          <button
            onClick={denyCookieModalHandler}
            className=" px-2 py-[2px] text-white rounded-md    duration-300  transition-all ease-in-out text-[19px]"
          >
            Deny
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBottomModal;
