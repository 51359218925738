import React, { Dispatch, FC, SetStateAction, useState } from "react";
import productImage from "../../../assets/product.jpg";
import { environments } from "../../../environment";
import { MainTitleTypeTwo } from "./MainTitleTypeTwo";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoClose } from "react-icons/io5";
interface ProductSectionProps {}

interface ProductCardProps {
  product: any;
}
const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const [isMouseMove, setIsMouseMove] = useState<boolean>(true);
  console.log("screen width : ", document.documentElement.scrollWidth);
  return (
    <div
      className={`col-span-12 w-[90%] mx-auto lg:col-span-3       h-[450px] relative overflow-hidden group  bg-[#F1F1EF]`}
    >
      <p
        className={`z-[20] absolute left-[10%] top-[8%] lg:text-[21px] text-[22px] font-[900] ${
          product?.textColor && product.textColor === "black"
            ? "text-black"
            : ""
        }`}
      >
        {product.title}
      </p>
      <p
        // style={{ textAlign: "justify" }}
        className={`z-[20] absolute left-[10%] top-[18%] lg:text-[12px]  text-[13px] font-[400]  bg-[rgba(0,0,0,0.5)] p-2 w-[92%] ${
          product?.textColor && product.textColor === "black"
            ? "text-white"
            : ""
        }`}
      >
        {product.subtitle}
      </p>
      {product?.bgImage ? (
        <div
          className={`  absolute ${
            product?.small
              ? product.small === 1
                ? `top-[50%] left-[35%] h-full w-full`
                : product?.small === 2
                ? "top-[50%] left-[35%] w-full h-full !bottom-0"
                : "h-full w-full"
              : "h-full w-full"
          } top-0 left-0 pt-6 px-8  group-hover:scale-[1.03] duration-500  `}
          style={{
            backgroundImage: `url(${product.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: product?.small ? "contain" : "cover",
          }}
        ></div>
      ) : product?.noImage ? (
        <div
          className={`h-full w-full group-hover:scale-[1.3] duration-300 absolute top-0 left-0 pt-6 px-8     group-hover:scale-[1.03] duration-500   `}
          style={{ backgroundColor: product.bgColor }}
        ></div>
      ) : (
        <></>
      )}
      {/* {product?.bgImage && !product?.small ? (
        <div
          className={`  absolute  top-0 left-0 bg-[rgba(0,0,0,0.3)] z-[66]  w-full h-full  group-hover:scale-[1.03] duration-500  `}
        ></div>
      ) : (
        <></>
      )} */}

      {/* <div
        className={`h-full w-full  duration-300 absolute top-0 left-0 pt-6 px-8 z-[3] bg-[rgba(0,0,0,0.5)]    `}
      >
        {" "}
      </div> */}
      <div
        className="lg:group-hover:block lg:hidden hidden absolute top-0 left-0 h-full w-full duration-500 z-[6] bg-gradient-to-t  "
        style={
          product?.bgColor
            ? { backgroundColor: product.bgColor }
            : product?.hoverImage
            ? {
                backgroundImage: product?.hoverImage
                  ? product.hoverImage
                  : product?.bgColor,
              }
            : {}
        }
      ></div>
      <div
        className={`h-full w-full lg:left-[120%] group-hover:left-[0%]  duration-300     z-[10] px-6  absolute ${
          product?.bottom ? `top-[39%]` : `top-[36%]`
        }  ${product.type === 1 ? "top-[38%] " : ``}`}
      >
        <p
          className={`lg:text-[14px] text-[17px] font-[350] ${
            document.documentElement.scrollWidth <= 450
              ? `px-4 py-5 bg-[rgba(0,0,0,0.4)] rounded-md text-white ${
                  product?.small ? `!text-white bg-[rgba(0,0,0,0.4)]` : ""
                }`
              : ``
          }   ${
            product?.textColor && product.textColor === "black"
              ? "text-black"
              : ""
          }`}
        >
          {product.description}
        </p>
      </div>
    </div>
  );
};

//  ********************************     product card for  mobile device  ********************************************  //
interface ProductCardForMobileDeviceProps {
  isSelected: number;
  setIsSelected: Dispatch<SetStateAction<number>>;
  productIndex: number;
  product: any;
}
const ProductCardForMobileDevice: FC<ProductCardForMobileDeviceProps> = ({
  isSelected,
  setIsSelected,
  productIndex,
  product,
}) => {
  return (
    <div
      className={`h-[423px] min-w-[82vw]  p-1 relative border-2 ${
        isSelected === productIndex
          ? product?.textColor && product.textColor === "black"
            ? `border-white`
            : `border-[#20A8D2]`
          : `border-transparent`
      }   overflow-hidden`}
      onClick={() => setIsSelected(productIndex)}
    >
      {isSelected === productIndex ? (
        <IoClose
          size={24}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSelected(-1);
          }}
          className={` absolute top-[8%] cursor-pointer z-[30] right-[10%]  ${
            product?.textColor && product.textColor === "black"
              ? "text-black"
              : ""
          }`}
        />
      ) : (
        <></>
      )}
      <p
        className={`z-[20] absolute left-[10%] top-[8%] lg:text-[21px] text-[22px] font-[900] ${
          product?.textColor && product.textColor === "black"
            ? "text-black"
            : ""
        }`}
      >
        {product.title}
      </p>
      <p
        // style={{ textAlign: "justify" }}
        className={`z-[20] absolute left-[10%] top-[18%] lg:text-[12px]  text-[13px] font-[400]  bg-[rgba(0,0,0,0.5)] p-2 w-[89%] ${
          product?.textColor && product.textColor === "black"
            ? "text-white"
            : ""
        }`}
      >
        {product.subtitle}
      </p>
      {product?.bgImage ? (
        <div
          className={`  absolute ${
            product?.small
              ? product.small === 1
                ? `top-[50%] left-[35%] h-full w-full`
                : product?.small === 2
                ? "top-[50%] left-[35%] w-full h-full !bottom-0"
                : "h-full w-full"
              : "h-[97.5%] w-[96.8%]"
          } top-[1.2%] left-[1.4%] pt-6 px-8  group-hover:scale-[1.03] duration-500  `}
          style={{
            backgroundImage: `url(${product.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: product?.small ? "contain" : "cover",
          }}
        ></div>
      ) : product?.noImage ? (
        <div
          className={`h-full w-full group-hover:scale-[1.3] duration-300 absolute top-0 left-0 pt-6 px-8     group-hover:scale-[1.03] duration-500   `}
          style={{ backgroundColor: product.bgColor }}
        ></div>
      ) : (
        <></>
      )}
      <div
        className={` ${
          isSelected === productIndex ? `block` : "hidden"
        }  absolute top-[1.2%] left-[1.4%] h-[97.5%] w-[96.8%] duration-500 z-[6] bg-gradient-to-t  `}
        style={
          product?.bgColor
            ? { backgroundColor: product.bgColor }
            : product?.hoverImage
            ? {
                backgroundImage: product?.hoverImage
                  ? product.hoverImage
                  : product?.bgColor,
              }
            : {}
        }
      ></div>

      <div
        className={`h-full w-full   ${
          isSelected === productIndex ? `left-[0%]` : `left-[120%]`
        }   duration-300     z-[10] px-6  absolute ${
          product?.bottom ? `top-[39%]` : `top-[36%]`
        }  ${product.type === 1 ? "top-[38%] " : ``}`}
      >
        <p
          className={`lg:text-[14px] text-[17px] font-[350]    ${
            product?.textColor && product.textColor === "black"
              ? "text-black"
              : ""
          }`}
        >
          {product.description}
        </p>
      </div>
    </div>
  );
};

const ProductSection: FC<ProductSectionProps> = (): JSX.Element => {
  const [isSelected, setIsSelected] = useState<number>(-1);
  return (
    <div className="mt-[50px] ">
      <MainTitleTypeTwo
        textBlocks={["OUR", "PRODUCTS"]}
        innerClassName="lg:min-w-[230px]"
      />
      <div className=" grid-cols-12 gap-x-[25px] gap-y-[50px] lg:px-[80px] mt-10 px-2 lg:px-0  hidden lg:grid ">
        {environments.productsList.map((self, index) => (
          <ProductCard product={self} key={index} />
        ))}
      </div>
      <div className="mt-10 px-2 flex space-x-1 overflow-x-auto no-scrollbar lg:hidden">
        {environments.productsList.map((self, index) => (
          <ProductCardForMobileDevice
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            productIndex={index}
            product={self}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
