export enum GlobalActionEnum {
  NAV_BAR_SCROLLED = "[GLOBAL] NAVBAR SCROLLED",
  NAV_BAR_NOT_SCROLLED = "[GLOBAL] NAVBAR NOT SCROLLED",
  CULTURE_SCROLLLED = "[GLOBAL] CULTURE SCROLLED",
  CULTURE_NOT_SCROLLED = "[GLOBAL] CULTURE NOT SCROLLED",
  AWARD_SECTION_SCROLLLED = "[GLOBAL] AWARD SECTION SCROLLED",
  AWARD_SECTION_NOT_SCROLLED = "[GLOBAL] AWARD SECTION NOT SCROLLED",
  SET_COOKIE_ENABLED = "[GLOBAL] IS COOKIE ENABLED",
  CLOSE_COOKIE_NOT_ENABLED = "[GLOBAL] IS COOKIE NOT ENABLED",
  MOBILE_MENU_OPEN = "[GLOBAL] MOBILE MENU OPEN",
  MOBILE_MENU_CLOSE = "[GLOBAL] MOBILE MENU CLOSE",
}

export const mobileMenuOpenHandler = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.MOBILE_MENU_OPEN });
};
export const mobileMenuCloseHandler = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.MOBILE_MENU_CLOSE });
};

export const setCookieModal = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.SET_COOKIE_ENABLED });
};

export const closeCookieModal = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.CLOSE_COOKIE_NOT_ENABLED });
};

export const navScrolledAction = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.NAV_BAR_SCROLLED });
};

export const navNotScrolledAction = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.NAV_BAR_NOT_SCROLLED });
};

export const CultureScrolledAction = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.CULTURE_SCROLLLED });
};

export const CultureNotScrolledAction = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.CULTURE_NOT_SCROLLED });
};

export const AwardSectionScrolledAction = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.AWARD_SECTION_SCROLLLED });
};

export const AwardSectionNotScrolledAction = () => (dispatch: any) => {
  dispatch({ type: GlobalActionEnum.AWARD_SECTION_NOT_SCROLLED });
};