import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { environments } from "../../../environment";
import firstCardBackground from "../../../assets/cards-01.png";
import secondCardBackground from "../../../assets/cards-02.png";
import thirdCardBackground from "../../../assets/cardbg3.jpg";
import fourthCardBackground from "../../../assets/cards-03.png";
import { LiaFirstAidSolid } from "react-icons/lia";
interface AwardSection2Props {}
const AwardSection2: FC<AwardSection2Props> = (): JSX.Element => {
  const awardTextScroll = useSelector(
    (state: StoreState) => state.globals.awardSectionScrolled
  );
  const [isSelected, setIsSelected] = useState<number>(-1);
  return (
    <div
      className={`lg:h-[300vh] h-[250vh]  w-screen  relative mt-[200px] lg:mt-5`}
      id="awardContainer"
    >
      <h1
        className={` ${
          awardTextScroll ? `fixed` : `absolute !top-[20%]`
        } lg:top-[30%] top-[40%] lg:left-[17%] left-[1%] text-center lg:!text-[95px] text-[46px] !z-[1] `}
      >
        INDUSTRY EXPERIENCE
      </h1>
      <div
        style={{
          backgroundImage: `url(${firstCardBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        onClick={() => setIsSelected((prev) => (prev === 1 ? -1 : 1))}
        className={` w-[80vw] lg:w-[550px] h-[360px] !z-[96] absolute top-[28%] lg:top-[32%] left-[4%] lg:left-[5%]  flex flex-col  pr-2 relative group overflow-hidden  `}
      >
        <div className="absolute left-0 top-0 w-full h-full  !z-[97]  bg-[rgba(0,0,0,0.3)]"></div>
        <div
          className={` text-[20px]   lg:text-[24px]   absolute lg:top-[80%] lg:group-hover:top-[10%] ${
            isSelected === 1 ? `top-[5%]` : `top-[80%]`
          }    lg:left-[20%] left-[5%] duration-500 !z-[100] `}
        >
          {environments.industryExperience[0].title}
        </div>
        <div
          className={` text-[13px]    lg:text-[17px]   mt-4  lg:px-4 absolute lg:top-[16%] top-[20%] h-full  ${
            isSelected === 1 ? `left-[10%]` : `left-[400%] `
          } lg:left-[400%]  lg:group-hover:left-[10%] duration-700  lg:leading-8 leading-7 !w-[90%] lg:!w-[100%] !z-[100] `}
        >
          <p
            className="lg:w-[90%]   p-2 lg:mt-5 "
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            {environments.industryExperience[0].details}
          </p>
        </div>
      </div>
      <div
        onClick={() => setIsSelected((prev) => (prev === 2 ? -1 : 2))}
        style={{
          backgroundImage: `url(${secondCardBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=" lg:w-[550px] w-[80vw] h-[360px] !z-[96] absolute top-[32%] lg:top-[35%] lg:left-[46%]  left-[14%] flex flex-col  pr-2 relative group overflow-hidden"
      >
        <div className="absolute left-0 top-0 w-full h-full  !z-[97]  bg-[rgba(0,0,0,0.3)]"></div>
        <div
          className={` text-[20px]   lg:text-[24px]   absolute lg:top-[80%] lg:group-hover:top-[10%] ${
            isSelected === 2 ? `top-[5%]` : `top-[80%]`
          }    lg:left-[20%] left-[5%] duration-500 !z-[100] `}
        >
          {environments.industryExperience[1].title}
        </div>
        <div
          className={` text-[13px]    lg:text-[17px]   mt-4  lg:px-4 absolute lg:top-[16%] top-[20%] h-full  ${
            isSelected === 2 ? `left-[10%]` : `left-[400%] `
          } lg:left-[400%]  lg:group-hover:left-[10%] duration-700   lg:leading-8 leading-7 !w-[90%] lg:!w-[100%] !z-[100] `}
        >
          <p
            className="lg:w-[90%]   p-2  lg:mt-10 mt-10"
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            {environments.industryExperience[1].details}
          </p>
        </div>
      </div>

      <div
        onClick={() => setIsSelected((prev) => (prev === 3 ? -1 : 3))}
        style={{
          backgroundImage: `url(${fourthCardBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="bg-[#20A8D2] w-[80vw] lg:w-[550px] left-[8%] h-[360px] !z-[96] absolute top-[36%]  lg:top-[38%] lg:left-[26%] flex flex-col  pr-2 relative group overflow-hidden"
      >
        <div className="absolute left-0 top-0 w-full h-full  !z-[97]  bg-[rgba(0,0,0,0.3)]"></div>
        <div
          className={` text-[20px]   lg:text-[24px]   absolute lg:top-[80%] lg:group-hover:top-[10%] ${
            isSelected === 3 ? `top-[5%]` : `top-[80%]`
          }    lg:left-[20%] left-[5%] duration-500 z-[100] `}
        >
          {environments.industryExperience[2].title}
        </div>
        <div
          className={` text-[13px]    lg:text-[17px]   mt-4  lg:px-4 absolute lg:top-[16%] top-[12%] h-full  ${
            isSelected === 3 ? `left-[10%]` : `left-[400%] `
          } lg:left-[400%]  lg:group-hover:left-[10%] duration-700   lg:leading-8 leading-7 !w-[90%] lg:!w-[100%]  z-[100]`}
        >
          <p
            className="lg:w-[90%]   p-2  lg:mt-10 mt-5"
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            {environments.industryExperience[2].details}
          </p>
        </div>
      </div>
      {/* <div className="bg-[#0041F0] w-[550px] h-[360px] !z-[96] absolute top-[65%] lg:left-[22%]"></div> */}
    </div>
  );
};

export default AwardSection2;
