import React, { FC } from "react";
import Carousel from "react-multi-carousel";
import { MdArrowForwardIos } from "react-icons/md";
import { environments } from "../../../environment";
interface HomeTextCarousalProps {
  playMarquee: boolean;
  PageRef: any;
}
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1199 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1200, min: 750 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 749, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const HomeTextCarousal: FC<HomeTextCarousalProps> = ({
  playMarquee,
  PageRef,
}) => {
  return (
    <Carousel
      itemClass="image-item"
      autoPlay={playMarquee}
      autoPlaySpeed={3000}
      responsive={responsive}
      ref={PageRef}
      pauseOnHover={false}
      infinite={true}
      arrows={false}
      className=" h-full w-full flex !items-end   "
    >
      {environments.serviceSection.map((self, index) => (
        <div
          className="w-full  !h-fullborder border-red-500  flex justify-center pb-1    "
          key={index}
        >
          <div className="h-full  w-full  lg:w-1/2  justify-center flex flex-col ">
            <p className="font-bold text-[30px]">{self.title}</p>
            <p
              className="mt-5 text-[15px] pb-3"
              style={{ textAlign: "justify" }}
            >
              {self.description}
            </p>
            {/* <div className="flex items-center gap-x-3">
              <p>Read More</p>
              <div className="w-[25px] h-[25px] bg-[#20A8D2] items-center justify-center flex">
                <MdArrowForwardIos />
              </div>
            </div> */}
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default HomeTextCarousal;
