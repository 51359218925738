import React, { FC, useState } from "react";
import { IoChevronUpSharp } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

import {
  Drawer,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { mobileMenuCloseHandler } from "../stores/actions/globalAction";
import { StoreState } from "../models/reduxModels";

interface MenuForMobileProps {
  ScrollToTopPos: any;
  serviceClick: any;
  whatWeDoHandler: any;
  contactUsMsqubeHandler: any;
  GenAIUseHandler: any;
  technologyHandler: any;
  aboutUsHandler: any;
}
const MenuForMobile: FC<MenuForMobileProps> = ({
  ScrollToTopPos,
  serviceClick,
  whatWeDoHandler,
  contactUsMsqubeHandler,
  GenAIUseHandler,
  technologyHandler,
  aboutUsHandler,
}): JSX.Element => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(mobileMenuCloseHandler() as any);
  };
  const mobileMenuOpen = useSelector(
    (state: StoreState) => state.globals.mobileMenuOpen
  );

  return (
    <div
      className={`!w-[100vw] h-[100vh] bg-black z-[999] fixed top-0 ${
        mobileMenuOpen ? `left-0` : `left-[300%]`
      }  duration-1000 ease-in-out `}
    >
      <div className="absolute top-5 left-5">
        <IoClose size={30} onClick={closeDrawer} />
      </div>
      <div className="mt-20 h-full w-full  flex flex-col space-y-8 p-8">
        <p
          className="text-xl "
          onClick={() => (ScrollToTopPos(), closeDrawer())}
        >
          Home
        </p>
        <p className="text-xl " onClick={() => (serviceClick(), closeDrawer())}>
          Our Products{" "}
        </p>
        <p
          className="text-xl "
          onClick={() => (aboutUsHandler(), closeDrawer())}
        >
          About Us
        </p>
        <p
          className="text-xl "
          onClick={() => (whatWeDoHandler(), closeDrawer())}
        >
          Our Services{" "}
        </p>

        <p
          className="text-xl "
          onClick={() => (technologyHandler(), closeDrawer())}
        >
          Technology Capability
        </p>
        <p
          className="text-xl "
          onClick={() => (contactUsMsqubeHandler(), closeDrawer())}
        >
          {" "}
          Contact Us
        </p>
      </div>
    </div>
  );
};

export default MenuForMobile;
