import React, { FC } from "react";
import FooterSubComponentOne from "./FooterSubComponentOne";
import FooterSubComponentTwo from "./FooterSubComponentTwo";
import MapComponent from "./MapComponent";
import { MdPhone, MdOutlineMail } from "react-icons/md";
import { FaLinkedin, FaFacebook } from "react-icons/fa6";

interface FooterProps {
  ScrollToTopPos: any;
  contactUsRef: any;
}

const Footer: FC<FooterProps> = ({
  ScrollToTopPos,
  contactUsRef,
}): JSX.Element => {
  return (
    <div
      className="md:flex  lg:h-[250px] py-5 px-3 lg:pl-[65px] space-y-10 lg:space-y-0  mt-[50px]"
      ref={contactUsRef}
      id="contactUsMsqube"
    >
      {/* <FooterSubComponentOne ScrollToTopPos={ScrollToTopPos} />
      <FooterSubComponentTwo /> */}
      <div className="lg:w-2/3 px-2 lg:px-0">
        <h1 className="lg:text-[20px] font-[900]">
          MSQUBE TECHNOLOGY SOLUTIONS PVT LTD.
        </h1>
        <p className="my-2">Unit #112, SDF Building</p>
        <p>
          Near RDB Cinemas, GP Block, Sector V, Bidhannagar,
          <br /> Kolkata, West Bengal 700091
        </p>
        <div className="flex items-center lg:space-x-2 mt-2">
          <MdPhone size={20} className="lg:block hidden" />
          <div className="flex items-center lg:space-x-2">+91 62897 43352</div>
        </div>
        <div className="flex lg:items-center lg:space-x-2 flex-col lg:flex-row  ">
          <MdOutlineMail size={20} className="hidden lg:block" />
          <div className="flex lg:items-center lg:space-x-2 lg:flex-row flex-col ">
            <a href="mailto:contactus@msqube.com"> contactus@msqube.com, </a>

            <a href="mailto:msqubeit@gmail.com"> msqubeit@gmail.com</a>
          </div>
        </div>
        <div className="flex items-center space-x-2 mt-2">
          <div className="flex items-center">
            <a
              href={`https://www.linkedin.com/company/msqube-tech/`}
              target="_blank"
              className="  !text-[14px] w-[90%]   "
            >
              <FaLinkedin size={20} />
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <a
              href={`https://www.facebook.com/MSQubeTech`}
              target="_blank"
              className="  !text-[14px] w-[90%]   "
            >
              <FaFacebook size={20} />
            </a>
          </div>
        </div>
        <div className="flex items-center"></div>
      </div>
      <div className="lg:w-1/3 ">
        <MapComponent />
      </div>
    </div>
  );
};

export default Footer;
