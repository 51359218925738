import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landing";

interface RootRouterProps {
  contactUsRef: any;
}
const RootRouter: FC<RootRouterProps> = ({ contactUsRef }) => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage contactUsRef={contactUsRef} />} />
      <Route path="*" element={<Navigate replace to={"/"} />} />
    </Routes>
  );
};

export default RootRouter;
