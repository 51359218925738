import React from "react";
import worldMap from "../../../assets/world_map-removebg-preview.png";
interface EnterpriseAppProps {}
const EnterpriseApp = () => {
  return (
    <div
      className={`w-screen  mt-[60px]   flex flex-col  relative  mx-auto px-5 py-5  bg-[#1B1D1E]    `}
    >
      <h1 className="lg:text-[34px] text-[23px] font-[900]">
        Enterprise Applications: Optimized Global Delivery
      </h1>
      <p
        className="mt-4 lg:text-[20px] text-[16px] italic lg:w-[96%]"
        style={{ textAlign: "justify" }}
      >
        We alongside our ecosystem partners posses the capability to quickly
        ramp up a team with the right mix of onsite, nearshore, and offshore
        practioners to balance skills, time zone, cultural, and cost objectives.
        Our Mission and Vision is to collaborate and partner with you in your
        Buisness Transformation Journey.{" "}
      </p>
      <div className="mt-[60px] flex lg:flex-row  flex-col justify-between">
        <div className="lg:w-[48%] w-full">
          <img
            src={worldMap}
            alt="logo"
            className="w-full lg:h-[340px] object-fill"
          />
        </div>
        <div className="lg:w-[48%] w-full mt-5 lg:mt-0">
          <div className="w-full border-t-2 border-b-2 border-white flex items-center px-3 py-3">
            <p className="w-1/2 font-[800] text-xl">Service Line</p>
            <p className="w-1/2 font-[800] text-xl">Applications</p>
          </div>
          <div className="w-full flex items-center px-3 py-3">
            <p className="w-1/2">Finance and Supply Chain Transformation</p>
            <p className="w-1/2">
              S/4 HANA On Cloud, S/4 HANA On-Prem, Ariba, EWM,IBP
            </p>
          </div>
          <div className="w-full flex items-center px-3 py-3  border-t-2 border-b-2 border-white">
            <p className="w-1/2">Customer Transformation</p>
            <p className="w-1/2">
              SAP Hybris, Salesforce, Elastic Path, MS Dynamics, Mulesoft
            </p>
          </div>
          <div className="w-full flex items-center px-3 py-3  ">
            <p className="w-1/2">HR Transformation</p>
            <p className="w-1/2">SAP SuccessFactors, Workday Concur</p>
          </div>
          <div className="w-full flex items-center px-3 py-3  border-t-2 border-b-2 border-white  ">
            <p className="w-1/2">Data and Technology Transformation</p>
            <p className="w-1/2">SAP BI, Sap DI, Power BI, Tableau</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseApp;
