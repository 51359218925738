import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { twMerge } from "tailwind-merge";
interface MainTitleTypeTwoProps {
  textBlocks: string[];
  className?: string;
  innerClassName?: string;
}

export const MainTitleTypeTwo: React.FC<MainTitleTypeTwoProps> = ({
  textBlocks,
  className,
  innerClassName,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1018);
  const [ref, inView] = useInView({
    triggerOnce: false,
  });
  // const textBlocks = ['Accelerate', 'Innovate', '&', 'Scale'];
  // const textBlocks = ["SERVICE", "OFFERINGS"];
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1018);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={twMerge("mt-0  cursor-pointer", className)}
      style={{
        backgroundColor: "black",
      }}
    >
      <div
        className="relative"
        ref={ref}
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="border-t border-white border-double absolute left-[30%] w-[200px] top-[48%] hidden lg:block "></div>
        <div className="border-t border-white border-double absolute left-[30%] w-[200px] top-[54%] hidden lg:block "></div>
        <div className="border-t border-white border-double absolute right-[30%] w-[200px] top-[48%] hidden lg:block "></div>
        <div className="border-t border-white border-double absolute right-[30%] w-[200px] top-[54%] hidden lg:block "></div>
        <div
          className={twMerge(
            "z-[2] bg-black lg:pr-1 lg:pl-1  lg:min-w-[250px] text-center  w-full lg:w-auto ",
            innerClassName
          )}
        >
          {textBlocks.map((text, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="text-white font-semibold text-center text-[25px] lg:text-[24px] "
              style={{
                margin: "1%",
              }}
            >
              {text}
            </motion.span>
          ))}
        </div>
      </div>
      {/* <div
        className="text-white text-2xl text-center mt-5 flex justify-center items-center"
        id="whatWeDo"
      >
        SCOPE OF SERVICES
      </div> */}
    </div>
  );
};