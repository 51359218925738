import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { FaCloudDownloadAlt } from "react-icons/fa";

import { useInView } from "react-intersection-observer";

interface MainTitleProps {}

export const MainTitle: React.FC<MainTitleProps> = () => {
  const [isHover, setIsHover] = useState(false);
  const [screenSize, setscreenSize] = useState(window.innerWidth < 1000);

  const fontSize = {
    isExtraLarge: screenSize ? "190%" : "600%",
    isLarge: screenSize ? "250%" : "500%",
    isMedium: screenSize ? "200%" : "400%",
    isSmall: screenSize ? "150%" : "300%",
  };

  const containerVariants = {
    hover: { marginLeft: "5%" },
    noHover: { marginLeft: "0%" },
  };

  const containerVariantTwo = {
    hover: { marginLeft: "5%" },
    noHover: { marginLeft: "10%" },
  };

  useEffect(() => {
    const handleResize = () => {
      setscreenSize(window.innerWidth < 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1018);
  const [ref, inView] = useInView({
    triggerOnce: false,
  });
  const textBlocks = ["Accelerate", "|", "Innovate", "|", "Scale"];
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1018);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const contactUsMsqubeHandler = () => {
    console.log("contact us msqube");
    document
      .getElementById("contactUsMsqube")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <motion.div
        className="lg:p-5 cursor-pointer mt-24 lg:mt-2 lg:block hidden"
        style={{ backgroundColor: "black" }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <motion.div
          className="text-white font-semibold text-center"
          // onClick={contactUsMsqubeHandler}
          style={{
            wordSpacing: "0.2em",
            width: "90%",
            fontSize: fontSize.isExtraLarge,
          }}
          variants={containerVariants}
          initial="noHover"
          animate={isHover ? "hover" : "noHover"}
          transition={{ type: "tween", duration: 0.3 }}
        >
          REINVENT WHAT YOUR
        </motion.div>
        <motion.div
          className="text-white font-semibold lg:text-center"
          style={{
            wordSpacing: "0.2em",
            width: "90%",
            fontSize: fontSize.isExtraLarge,
          }}
          variants={containerVariantTwo}
          initial="noHover"
          animate={isHover ? "hover" : "noHover"}
          transition={{ type: "tween", duration: 0.3 }}
        >
          BUSINESS COULD BE
        </motion.div>
        <div
          className="mt-0 lg:px-10 cursor-pointer "
          style={{
            backgroundColor: "black",
          }}
        >
          <div
            ref={ref}
            id="productSection_msqube"
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {textBlocks.map((text, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="text-white font-semibold text-center uppercase"
                style={{
                  fontSize: isSmallScreen ? "102%" : "240%",
                  margin: "1%",
                }}
              >
                {text}
              </motion.span>
            ))}
          </div>
          {/* <div
        className="text-white text-2xl text-center mt-5 flex justify-center items-center"
        id="whatWeDo"
      >
        SCOPE OF SERVICES
      </div> */}
        </div>
        {/* <div className="text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mt-5 flex justify-center items-center animate-pulse">
        Accelerate Innovate Scale{" "}
        <div>
          <a href={"/company_profile.pdf"} download>
            <FaCloudDownloadAlt className="text-[#1FA8D2] ml-2" />
          </a>
        </div>
      </div> */}
      </motion.div>
      <div className="text-[60px] lg:hidden mt-24  flex flex-col">
        <p
          className="font-[900]  leading-none"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          REINVENT
        </p>
        <p
          className="font-[900] ml-auto leading-none"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          WHAT YOUR
        </p>
        <p
          className="font-[900] leading-none"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          BUSINESS
        </p>
        <p
          className="font-[900] ml-auto leading-none"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          COULD BE
        </p>
      </div>
    </>
  );
};
