import { GlobalModel } from "../../models/globalModel";
import { GlobalActionEnum } from "../actions/globalAction";

import InitialState from "../initialState";

const initialState: GlobalModel = InitialState.globals;

export default function GlobalReducer(
  state: GlobalModel = initialState,
  action: any
) {
  switch (action.type) {
    case GlobalActionEnum.NAV_BAR_SCROLLED:
      return { ...state, navScrolled: true };
    case GlobalActionEnum.NAV_BAR_NOT_SCROLLED:
      return { ...state, navScrolled: false };
    case GlobalActionEnum.CULTURE_SCROLLLED:
      return { ...state, cultureScrolled: true };
    case GlobalActionEnum.CULTURE_NOT_SCROLLED:
      return { ...state, cultureScrolled: false };
    case GlobalActionEnum.AWARD_SECTION_SCROLLLED:
      return { ...state, awardSectionScrolled: true };
    case GlobalActionEnum.AWARD_SECTION_NOT_SCROLLED:
      return { ...state, awardSectionScrolled: false };
    case GlobalActionEnum.SET_COOKIE_ENABLED:
      return { ...state, isCookieEnabled: true };
    case GlobalActionEnum.CLOSE_COOKIE_NOT_ENABLED:
      return { ...state, isCookieEnabled: false };
    case GlobalActionEnum.MOBILE_MENU_OPEN:
      return { ...state, mobileMenuOpen: true };
    case GlobalActionEnum.MOBILE_MENU_CLOSE:
      return { ...state, mobileMenuOpen: false };
    default:
      return state;
  }
}
