import React from "react";

import SAPHana from "../../../assets/Picture3.png";
import SAP33 from "../../../assets/Picture2.png";
import SAPbw4hana from "../../../assets/Picture4.png";
import sapmdg from "../../../assets/Picture5.png";
import sapbods from "../../../assets/Picture6.png";
import sapeim from "../../../assets/Picture7.png";
import sapcpi from "../../../assets/Picture8.png";
import sapabponhana from "../../../assets/Picture9.png";
import sap_firori from "../../../assets/Picture10.png";
import sap_loogo from "../../../assets/Picture11.png";
import { GoDot } from "react-icons/go";
const SAPSection = () => {
  return (
    <div
      className={`w-screen  mt-[60px]   flex flex-col  relative  mx-auto lg:px-5 py-4  bg-[#1B1D1E]    `}
    >
      <h1 className="lg:text-[34px] text-[23px] font-[900] px-5">
        Strong ecosystem for end-to-end SAP solutions
      </h1>
      <p
        className="mt-4 lg:text-[20px] text-[16px]  lg:w-[96%] px-5"
        style={{ textAlign: "justify" }}
      >
        To deliver an end-to-end framework to build intelligent workflows in an
        SAP centric world, with the help of our eco-system partners we have
        built the capability across a wide array of technical areas to cater
        implementation Services, Support Services and Development Services for
        our customers.
      </p>
      <div className=" lg:grid lg:grid-cols-12 lg:gap-x-[50px] gap-y-[50px] lg:px-[80px] no-scrollbar  space-x-4 mt-8 px-2 lg:px-0  flex overflow-x-auto lg:overflow-x-hidden ">
        <div
          className={` w-[100%] mx-auto lg:col-span-3  min-w-[82vw]     lg:min-w-full     h-[450px] relative overflow-hidden group border-4 border-[#022985]  bg-white `}
        >
          <div className="w-full h-[20%] bg-[#022985] flex items-center justify-center">
            <p className="text-[20px] text-center font-[800]">Data Analytics</p>
          </div>
          <div className="mt-2 flex flex-col items-center space-y-12 py-2 pt-3  h-full items-between">
            <img src={SAPHana} className="w-[86px] h-[60px]" />
            <img src={SAP33} className="w-[190px] h-[50px] " />
            <div className="w-full flex space-between px-2 space-x-2 ">
              <img src={SAPbw4hana} className="w-[100px] h-[70px] " />
              <img src={sapmdg} className="w-[100px] h-[70px] " />
            </div>
          </div>
        </div>
        <div
          className={` w-[100%] mx-auto lg:col-span-3 min-w-[82vw]  lg:min-w-full  min-w-[82vw]  lg:min-w-auto       h-[450px] relative overflow-hidden group border-4 border-[#FDDD69] bg-white `}
        >
          <div className="w-full h-[20%] bg-[#FDDD69] flex items-center justify-center  ">
            <p className="text-[20px] text-black text-center font-[800]">
              Data Migration and Integration
            </p>
          </div>
          <div className="mt-2 flex flex-col items-center space-y-12 py-2 pt-3  h-full items-between">
            <img src={sapbods} className="w-[190px] h-[50px] " />
            <img src={sapeim} className="w-[190px] h-[50px] " />

            <img src={sapcpi} className="w-[190px] h-[100px] " />
          </div>
        </div>
        <div
          className={` w-[100%] mx-auto lg:col-span-3  min-w-[82vw]  lg:min-w-full     h-[450px] relative overflow-hidden group border-4 border-[#20A8D2]  bg-white`}
        >
          <div className="w-full h-[20%] bg-[#20A8D2] flex items-center justify-center  ">
            <p className="text-[20px]  text-center font-[800]">
              Custom Development
            </p>
          </div>
          <div className="mt-2 flex flex-col items-center space-y-12 py-2 pt-3  h-full items-between">
            <img src={sapabponhana} className="w-[190px] h-[50px] " />
            <img src={sap_firori} className="w-[190px] h-[50px] " />

            <img src={sap_loogo} className="w-[190px] h-[100px] " />
          </div>
        </div>

        <div
          className={` w-[100%] mx-auto lg:col-span-3  min-w-[82vw]  lg:min-w-full     h-[450px] relative overflow-hidden group border-4 border-[#548235] bg-white  `}
        >
          <div className="w-full h-[20%] bg-[#548235] flex items-center justify-center  ">
            <p className="text-[20px]  text-center font-[800]">
              Process Consulting
            </p>
          </div>
          <div className="mt-2 flex flex-col  space-y-3 py-1  h-full ">
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900] ">Asset Management</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Finance</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Human Resource</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Manufacturing</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Procurement</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">
                R & D / Engineering
              </p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Sales</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Service</p>
            </div>
            <div className="flex space-x-4 px-2 items-end">
              <GoDot className="text-black" />
              <p className="text-black italic font-[900]">Supply Chain</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAPSection;
