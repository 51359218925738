import React, { FC } from "react";
import aboutUsMsQUBE from "../../../assets/aboutUSMSQUBE.png";
import { MainTitleTypeTwo } from "./MainTitleTypeTwo";
import { GoDotFill } from "react-icons/go";
import { environments } from "../../../environment";
import { ImCheckboxChecked } from "react-icons/im";
import ceoImage from "../../../assets/1523154743199.jpeg";

interface AboutUSProps {}

const AboutUS: FC<AboutUSProps> = (): JSX.Element => {
  return (
    <div
      className={`w-screen   mt-[100px]  bg-primaryBgColor flex flex-col  relative  mx-auto    `}
      id="aboutUsMSQUBE"
    >
      {/* <h1 className="text-white text-[40px] lg:text-[70px] text-center lg:mt-5 flex justify-center items-center z-[10] absolute -top-[8%] lg:static">
        Our GenAI Offering
      </h1> */}
      <MainTitleTypeTwo
        textBlocks={["ABOUT ", " US"]}
        className="w-full "
        innerClassName="lg:min-w-[230px]"
      />
      <div className="w-full flex flex-col-reverse lg:flex-row  justify-between space-y-5 lg:space-y-0 lg:mt-10 lg:min-h-[280px] mt-8 ">
        <div className="w-full   space-y-8 py-5 px-3   bg-[#1B1D1E]  flex">
          <div className="w-full mx-auto h-full  pt-4 flex   px-2 lg:flex-row flex-col space-y-4  ">
            <div className="flex flex-row lg:space-x-4 justify-center lg:w-[14%] w-full">
              <img
                alt="ceoimage"
                src={ceoImage}
                className="w-[180px] h-[180px] rounded-full  border-4 border-black"
              />
            </div>
            <div className="lg:w-[80%] lg:mt-4 w-full lg:pl-4">
              <div className="w-full relative">
                <h1 className=" lg:text-[20px] font-[800]">FROM CEO'S DESK</h1>
                <div className=" border border-white w-[300px] "></div>
              </div>

              <p
                className="lg:mt-2 mt-4 lg:w-[96%] text-[13px] lg:text-base leading-6 "
                style={{ textAlign: "justify" }}
              >
                MSQUBE is a technology solution company specializes in
                Enterprise Applications, Automation, Artificial
                Intelligence/Machine Learning, Blockchain, Cloud and Mobile
                solutions with its Head office, Research, and Development center
                located in Kolkata, India. With our deep industry expertise, we
                help enable organizations to transform digitally, bring agility
                in running the business differently and enable to grow faster.
                Our management team over the last 20 years had conceptualized,
                designed and delivered Large Scale Enterprise IT transformation
                program globally and confident to expand and become a global
                market leader in providing these services.
              </p>
              <div className="flex justify-end lg:w-[98%]">
                <div className="mt-2 flex flex-col ">
                  <p className="text-[14px]"> Manzoor Hasan</p>
                  <p className="text-[13px]">Chief Executive Officer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUS;
