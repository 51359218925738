import React, { FC } from "react";
import genAi from "../../../assets/genAISection.jpg";
import { MainTitleTypeTwo } from "./MainTitleTypeTwo";
import { GoDotFill } from "react-icons/go";
import { environments } from "../../../environment";
interface GenAISectionProps {}

const GenAISection: FC<GenAISectionProps> = (): JSX.Element => {
  return (
    <div
      className={`w-screen   mt-[60px]  bg-primaryBgColor flex flex-col  relative  mx-auto     `}
      id="genAiSection"
    >
      {/* <h1 className="text-white text-[40px] lg:text-[70px] text-center lg:mt-5 flex justify-center items-center z-[10] absolute -top-[8%] lg:static">
        Our GenAI Offering
      </h1> */}
      {/* <MainTitleTypeTwo
        textBlocks={["GENERATIVE ", " AI"]}
        className="w-full "
        innerClassName="lg:min-w-[230px]"
      /> */}
      <div className="w-full flex flex-col-reverse lg:flex-row  justify-between space-y-5 lg:space-y-0  lg:min-h-[480px]  ">
        <div className="w-full lg:w-1/2 lg:px-10 px-8 space-y-8 py-4  bg-[#1B1D1E] ">
          <h1 className="lg:text-[34px] text-[23px] font-[900]">
            Generative AI
          </h1>
          {environments.genaiDetails.map((self, index) => (
            <div className="flex items-center space-x-2 " key={index}>
              <p className="hover:underline underline-offset-4 duration-500 ease-in-out cursor-pointer">
                {self.text}
              </p>
            </div>
          ))}
        </div>
        <div
          className="w-full lg:w-1/2 relative flex lg:pt-16 pt-10  flex-col items-center lg:h-auto h-[340px]  "
          // style={{
          //   backgroundImage: `url(${genAi})`,
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "cover",
          // }}
        >
          <img
            src={genAi}
            className="w-full h-full object-filled absolute top-0 left-0"
          />
          <div className="absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.3)] z-[2]"></div>
          {/* <h1 className="z-[3] text-[60px] text-white shadow-2xl shadow-gray-800 px-2 h-[70px]">
            Generative AI
          </h1> */}
          {/* <p className="z-[3] mt-3 text-[20px]">Explore our latest insights</p> */}
        </div>
      </div>

      {/*
      <div className=" absolute lg:top-[25%] top-[38.5%] lg:left-[34%] left-[7%] z-[4]  ">
        <img
          src={genAi}
          alt="genai"
          className="lg:w-[450px] w-[300px] lg:h-[450px] h-[300px] rounded-full shadow-lg lg:border-8 border-4 border-black"
        />
      </div>

      <div className="bg-[#1B1D1E] absolute left-[0%] w-full  h-32 lg:top-[28%] top-[0%] p-2 rounded-md">
        <div className="border-2 border-black w-full h-full text-white p-3 py-6 flex  justify-between lg:pr-[70px]">
          <p className=" text-[15px] lg:text-[17px] font-[800]">
            Comparative study of LLM models - LLAMA, OpenAI, Gemini <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
          <p className="text-[17px] font-[800] lg:block hidden">
            Implement Q&A using Langchain PromptTemplate - <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
        </div>
      </div> */}

      {/*  ******************************   for Mobile  ****************************************************  */}
      {/* <div className="bg-[#1B1D1E] absolute left-[0%] w-full  h-32 lg:top-[28%] top-[13%] p-2 rounded-md lg:hidden">
        <div className="border-2 border-black w-full h-full text-white p-3 py-4 flex  justify-between lg:pr-[70px]">
          <p className="text-[17px] font-[800] ">
            Implement Q&A using Langchain PromptTemplate - <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
        </div>
      </div>

      <div className="bg-[#1B1D1E] absolute left-[0%] w-full  h-32 lg:top-[55%] top-[26%] p-2 rounded-md">
        <div className="border-2 border-black w-full h-full text-white p-3 py-4 flex  justify-between lg:pr-[125px]">
          <p className="text-[15px] lg:text-[17px] font-[800] ">
            Multimodal LLM offering,
            <br className="lg:block hidden" />
            GenAI based image reading and translation <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
          <p className="text-[17px] font-[800] lg:block hidden">
            Speech to text with sentiment analysis - <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
        </div>
      </div> */}

      {/*  ******************************   for Mobile  ****************************************************  */}

      {/* <div className="bg-[#1B1D1E] absolute left-[0%] w-full  h-32 lg:top-[55%] top-[65%] p-2 rounded-md lg:hidden ">
        <div className="border-2 border-black w-full h-full text-white p-3 py-4 flex  justify-between lg:pr-[125px]">
          <p className="text-[17px] font-[800] ">
            Speech to text with sentiment analysis - <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
        </div>
      </div>

      <div className="bg-[#1B1D1E] absolute left-[0%] w-full  h-32 lg:top-[82%] top-[77.5%] p-2 rounded-md">
        <div className="border-2 border-black w-full h-full text-white p-3 py-4 flex  justify-between lg:pr-[90px]">
          <p className="text-[17px] font-[800]">
            RAG implementation on GenAI <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
          <p className="text-[17px] font-[800] lg:block hidden">
            Comparison of VectorDB - FAISS, Milvus, Chroma <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
        </div>
      </div> */}

      {/*  ********************************************************     Mobile Menu ************************************** */}
      {/* <div className="bg-[#1B1D1E] absolute left-[0%] w-full  h-32  top-[90%] p-2 rounded-md lg:hidden">
        <div className="border-2 border-black w-full h-full text-white p-3 py-4 flex  justify-between lg:pr-[90px]">
          <p className="text-[17px] font-[800] ">
            Comparison of VectorDB - FAISS, Milvus, Chroma <br />{" "}
            <span className="text-blue-500 text-sm">Coming Soon ...</span>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default GenAISection;
