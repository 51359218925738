import { StoreState } from "./../models/reduxModels";
const initialState: StoreState = {
  globals: {
    navScrolled: true,
    cultureScrolled: false,
    awardSectionScrolled: false,
    isCookieEnabled: false,
    mobileMenuOpen: false,
  },
};
export default initialState;
