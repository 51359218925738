import React, { FC, useRef, useState } from "react";
import HomeImageCarousal from "./HomeImageCarousal";
import HomeTextCarousal from "./HomeTextCarousal";
import { IoIosPlay } from "react-icons/io";
import { BsFillPauseFill } from "react-icons/bs";
import { HiArrowLongLeft } from "react-icons/hi2";
import { HiArrowLongRight } from "react-icons/hi2";
import { FaSquare } from "react-icons/fa6";
interface HomeCarousalWrapperProps {}
const HomeCarousalWrapper: FC<HomeCarousalWrapperProps> = () => {
  const [playMarquee, setPlayMarquee] = useState<boolean>(true);
  const carousalRef = useRef<any>();
  const carousalRef2 = useRef<any>();

  console.log("carousal : ", carousalRef.current);

  const handleNextSlideClick = () => {
    if (carousalRef?.current && carousalRef2?.current) {
      console.log("hit");
      carousalRef.current.next();
      carousalRef2.current.next();
      console.log("currIndex : ", carousalRef.current.state.currentSlide);
    }
  };

  const handlenPrevSlideClick = () => {
    if (carousalRef?.current && carousalRef2?.current) {
      console.log("hit");
      carousalRef.current.previous();
      carousalRef2.current.previous();
      console.log("currIndex : ", carousalRef.current.state.currentSlide);
    }
  };

  return (
    <div className="lg:flex flex-col justify-between items-center  ">
      <div className="w-screen lg:flex-row h-[500px] lg:flex-row flex-col flex items-center justify-between  px-2 lg:px-0">
        <div className="overflow-hidden  lg:w-1/2 w-full h-full">
          <HomeImageCarousal playMarquee={playMarquee} PageRef={carousalRef} />
        </div>
        <div className=" lg:w-1/2 w-full  h-full">
          <HomeTextCarousal playMarquee={playMarquee} PageRef={carousalRef2} />
        </div>
      </div>
      <div className="w-full mt-3  lg:pl-[80px]  flex justify-between items-center px-3 lg:px-0">
        <div className="flex items-center gap-x-5">
          <div>
            {playMarquee ? (
              <BsFillPauseFill
                size={30}
                onClick={() => setPlayMarquee((prev) => !prev)}
                className="cursor-pointer"
              />
            ) : (
              <IoIosPlay
                size={30}
                onClick={() => setPlayMarquee((prev) => !prev)}
                className="cursor-pointer"
              />
            )}
          </div>
          <div className="flex items-center gap-x-3">
            <HiArrowLongLeft
              size={30}
              className="cursor-pointer"
              onClick={handlenPrevSlideClick}
            />
            <HiArrowLongRight
              size={30}
              className="cursor-pointer"
              onClick={handleNextSlideClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousalWrapper;
