import React, { FC, useState } from "react";
import Marquee from "react-fast-marquee";
import { FaPause } from "react-icons/fa6";
import { IoIosPlay } from "react-icons/io";
import { BsFillPauseFill } from "react-icons/bs";
import { environments } from "../../../environment";
import { MainTitleTypeTwo } from "./MainTitleTypeTwo";
interface NewsBlockProps {
  setPlayMarquee: any;
  playMarquee: boolean;
  details: any;
}
const NewsBlock: FC<NewsBlockProps> = ({
  setPlayMarquee,
  playMarquee,
  details,
}) => {
  return (
    <div className="  w-2/3 lg:min-w-[500px]  group">
      <p className=" text-white  group-hover:text-gray-400 duration-500 ease-in lg:text-[27px] font-[900] ">
        {details.title}
      </p>
      <div
        className="lg:text-[20px] text-[25px] text-white lg:max-w-[120vw] lg:max-h-[100px]  group-hover:text-gray-400 duration-500 ease-in"
        style={{ textDecoration: "justify" }}
      >
        {details.description}
      </div>
    </div>
  );
};

const NewsSection = () => {
  const [playMarquee, setPlayMarquee] = useState<boolean>(true);

  return (
    <div className=" mt-[80px]  mb-[0px]    " id="technlogy_capability">
      {/* <p className="lg:ml-[60px] text-[25px] mb-[50px]">
        Technology Capability
      </p> */}
      <div className="my-4">
        <MainTitleTypeTwo
          textBlocks={["TECHNOLOGY", "CAPABILITY"]}
          innerClassName="lg:min-w-[378px]  "
        />
      </div>
      <Marquee
        pauseOnHover
        className="gap-x-5 flex flex-col justify-start"
        speed={130}
        play={playMarquee}
        // play={Boolean(playMarquee)}
      >
        {environments.technologicalCapabilities.map((self, index) => (
          <NewsBlock
            key={index}
            details={self}
            setPlayMarquee={setPlayMarquee}
            playMarquee={playMarquee}
          />
        ))}
      </Marquee>
      <div className="lg:ml-[60px] mt-5">
        {playMarquee ? (
          <BsFillPauseFill
            size={30}
            onClick={() => setPlayMarquee((prev) => !prev)}
            className="cursor-pointer"
          />
        ) : (
          <IoIosPlay
            size={30}
            onClick={() => setPlayMarquee((prev) => !prev)}
            className="cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default NewsSection;
